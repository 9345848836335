import { Tabs } from 'antd';
import React from 'react';
import { useParams } from 'react-router-dom';
import ItemForm from '../ItemForm';
import ItemVariationEditor from './ItemVariationEditor';


const ItemSinglePage = () => {
    let { id } = useParams();
    return (
        <Tabs defaultActiveKey={"1"}>
            <Tabs.TabPane key={"1"} tab="Basic">
                <ItemForm />
            </Tabs.TabPane>

            <Tabs.TabPane key={"2"} tab="Variations">
                <ItemVariationEditor itemId={id} />
            </Tabs.TabPane>

        </Tabs>
    )
};

export default ItemSinglePage;