import React from 'react';
import { useFieldArray, Controller, Control, FieldErrors } from 'react-hook-form';
import { Col, Row, Input, Button, Space, Card } from 'antd';
import { FieldErrorMessage } from './FieldErrorMessage';
import Icon, { DeleteOutlined, PlusOutlined, MailOutlined, VideoCameraOutlined } from '@ant-design/icons';

type YoutubeVideosArrayFieldProps = {
    control: Control,
    fieldName: string,
    errors: FieldErrors<any>

};
const YoutubeVideosArray = ({ control, fieldName, errors }: YoutubeVideosArrayFieldProps) => {
    const { fields, append, prepend, remove, swap, move, insert } = useFieldArray(
        {
            control,
            name: fieldName
        }
    );


    return (
        <Card bodyStyle={{ padding: 15 }} title="Videos" >
            <Space style={{ width: '100%' }} direction="vertical">
                {fields?.map((item, index) => {
                    return (
                        <div key={index}>
                            <Row style={{ width: "100%" }} gutter={12}>
                                <Col span={22}>
                                    <Controller
                                        control={control}
                                        rules={{
                                            required: true,
                                        }}
                                        defaultValue={item.value?.id}

                                        as={<Input prefix={<VideoCameraOutlined />}

                                            placeholder="ID" id={`${fieldName}[${index}].id`} />}
                                        name={`${fieldName}[${index}].id`}
                                    />




                                </Col>
                                <Col span={2}>
                                    <Button block style={{ height: "100%" }} onClick={() => { remove(index) }} icon={<DeleteOutlined />}></Button>
                                </Col>
                            </Row>
                            <FieldErrorMessage errors={errors} name={`${fieldName}[${index}]`} />
                        </div>


                    );
                })}
                <Button block onClick={() => { append({}) }} icon={<PlusOutlined />}></Button>
                <FieldErrorMessage errors={errors} name={fieldName} />
            </Space>

        </Card >
    )

};

export default YoutubeVideosArray;