export const PaymentMethods = {
  cashOnDelivery: "CASH_ON_DELIVERY",
  creditCard: "CREDIT_CARD",
  format(v: string) {
    switch (v) {
      case "CASH_ON_DELIVERY":
        return "Cash On Delivery";
      case "CREDIT_CARD":
        return "Credit Card";
      case "CREDIT_CARD_VISA":
        return "Visa Credit Card";
      case "CREDIT_CARD_MASTER":
        return "Mastercard Credit Card";
    }
  },
};

export const OrderStatus = {
  pending: "PENDING",
  awaitingPayment: "AWAITING_PAYMENT",
  awaitingFulfillment: "AWAITING_FULFILLMENT",
  awaitingShipping: "AWAITING_SHIPPING",
  shipped: "SHIPPED",
  completed: "COMPLETED",
  canceled: "CANCELED",
  refunded: "REFUNDED",
  disputed: "DISPUTED",
  partiallyRefunded: "PARTIALLY_REFUNDED",
  format(v: string) {
    switch (v) {
      case "PENDING":
        return "Pending";
      case "AWAITING_PAYMENT":
        return "Awaiting Payment";
      case "AWAITING_FULFILLMENT":
        return "Awaiting  Fulfillment";
      case "AWAITING_SHIPPING":
        return "Awaiting Shipping";
      case "SHIPPED":
        return "Shipped";
      case "COMPLETED":
        return "Completed";
      case "CANCELED":
        return "Canceled";
      case "REFUNDED":
        return "Refunded";
      case "DISPUTED":
        return "Disputed";
      case "PARTIALLY_REFUNDED":
        return "Refunded";
    }
  },
};
