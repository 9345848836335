import React, { useState } from 'react';
import { Button, Col, Form, Input, Layout, Row } from 'antd';
import './login.css';
import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { Api } from "../api";
import { useDispatch, } from 'reactive-react-redux';
import { Redux_Actions } from "../store";
import { useHistory } from 'react-router-dom';

export const Login = () => {
    const dispatch = useDispatch();
    let history = useHistory();
    const [loading, setLoading] = useState(false);
    const onFinish = ({ username, password }: any) => {
        setLoading(true);

        Api.login({ username, password }).then((res) => {
            // console.log(res);
            console.log(res);
            dispatch({ type: Redux_Actions.login, payload: { token: res.data.token, user: res.data.user } });
            history.replace("/");
        }).finally(() => {
            setLoading(false);

        });
    };

    return (
        <>
            <Row justify={"center"} className={"login-container"} style={{ height: "90vh", alignItems: "center" }}>
                <Col span={6}>
                    <img alt={"logo"} src={"/logo.svg"} style={{ marginBottom: 5, width: "100%", height: "auto" }} />
                    <Form
                        name="normal_login"
                        className="login-form"
                        initialValues={{ remember: true }}
                        onFinish={onFinish}
                    >
                        <Form.Item
                            name="username"
                            rules={[{ required: true, message: 'Please input your Username!' }]}
                        >
                            <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Username" />
                        </Form.Item>
                        <Form.Item
                            name="password"
                            rules={[{ required: true, message: 'Please input your Password!' }]}
                        >
                            <Input
                                prefix={<LockOutlined className="site-form-item-icon" />}
                                type="password"
                                placeholder="Password"
                            />
                        </Form.Item>
                        <Form.Item>
                            <Button loading={loading} type="primary" htmlType="submit" className="login-form-button">
                                Log in
                            </Button>
                        </Form.Item>
                    </Form>
                </Col>


            </Row>
            <Layout.Footer>
                Get Your System Now | All rights reserved ©{new Date().getFullYear()} | Created By <a rel="noopener noreferrer" target={"_blank"}
                    href={"https://n-idea.net"}>N
                    IDEA</a>
            </Layout.Footer>
        </>
    );
};