import { Button, Col, Input, message, Result, Row, Space, Typography } from "antd";
import React from "react";
import { Controller, useForm } from "react-hook-form";
import { Link, useHistory, useParams } from "react-router-dom";
import { Api } from "../api";
import { useSubmitForm } from "../helpers/forms";
import { useLoadFormData } from "../hooks/useLoadFormData";
import { FieldErrorMessage } from "./FieldErrorMessage";
import FileUploadV2 from "./fileUploadV2/FileUploadV2";

const BrandForm = () => {
  let { id } = useParams();
  // const [notFound, setNotFound] = useState(false);
  const { handleSubmit, errors, control, reset } = useForm({});
  let history = useHistory();
  const onSuccess = () => {
    message.success("Saved");
    history.push("/brands");
  };
  // const imageFields = [
  //     {
  //         name: "image",
  //         singleValue: true,
  //     }
  // ];

  const [notFound, loading] = useLoadFormData({
    apiCall: Api.brands.single,
    id,
    setValue: reset,
    //  imageFields
  });
  const [loadingSubmit, submit] = useSubmitForm({
    createApiCall: Api.brands.create,
    updateApiCall: Api.brands.update,
    onSuccess,
    //  imageFields,
    updateId: id,
  });

  if (notFound)
    return (
      <Result
        status="404"
        title="404"
        subTitle="Sorry, document does not exist"
        extra={
          <Button type="primary">
            <Link to="/brands">Back to list</Link>
          </Button>
        }
      />
    );

  return (
    <form className="" onSubmit={handleSubmit(submit)}>
      <Space size="middle" style={{ width: "100%" }} direction="vertical">
        <div className="white-container">
          <Typography.Title level={4}>Basic Info</Typography.Title>
          <Row style={{ width: "100%" }} gutter={12}>
            <Col span={6}>
              <label htmlFor={"name"}>Name</label>
              <Controller
                control={control}
                as={<Input id={"name"} />}
                rules={{ required: true }}
                name="name"
              />
              <FieldErrorMessage errors={errors} name="name" />
            </Col>
          </Row>
        </div>
        <div className="white-container">
          <Typography.Title level={4}>Address</Typography.Title>
          <Row style={{ width: "100%" }} gutter={12}>
            <Col span={24}>
              <label htmlFor={"address.street"}>Street Address</label>
              <Controller
                control={control}
                as={<Input id={"address.street"} />}
                name="address.street"
              />
              <FieldErrorMessage errors={errors} name="address.street" />
            </Col>
          </Row>
          <Row style={{ width: "100%" }} gutter={12}>
            <Col span={12}>
              <label htmlFor={"address.city"}>City / Town</label>
              <Controller
                control={control}
                as={<Input id={"address.city"} />}
                name="address.city"
              />
              <FieldErrorMessage errors={errors} name="address.city" />
            </Col>
            <Col span={12}>
              <label htmlFor={"address.state"}>State</label>
              <Controller
                control={control}
                as={<Input id={"address.state"} />}
                name="address.state"
              />
              <FieldErrorMessage errors={errors} name="address.state" />
            </Col>

            <Col span={12}>
              <label htmlFor={"address.zip"}>ZIP / Postal Code</label>
              <Controller control={control} as={<Input id={"address.zip"} />} name="address.zip" />
              <FieldErrorMessage errors={errors} name="address.zip" />
            </Col>
          </Row>
        </div>
        <div className="white-container">
          <Typography.Title level={4}>Contact</Typography.Title>
          <Row style={{ width: "100%" }} gutter={12}>
            <Col span={12}>
              <Typography.Paragraph>Phone</Typography.Paragraph>
              <Row gutter={12}>
                <Col span={8}>
                  <label htmlFor={"contact.phoneNumber.countryCode"}>Country Code</label>
                  <Controller
                    control={control}
                    as={<Input />}
                    rules={{ required: false }}
                    name="contact.phoneNumber.countryCode"
                  />
                  <FieldErrorMessage errors={errors} name="contact.phoneNumber.countryCode" />
                </Col>
                <Col span={16}>
                  <label htmlFor={"contact.phoneNumber.number"}>Number</label>
                  <Controller
                    control={control}
                    as={<Input />}
                    rules={{ required: false }}
                    name="contact.phoneNumber.number"
                  />
                  <FieldErrorMessage errors={errors} name="contact.phoneNumber.number" />
                </Col>
              </Row>
            </Col>
          </Row>
          <Row style={{ width: "100%" }} gutter={12}>
            <Col span={12}>
              <label htmlFor={"contact.email"}>Email</label>
              <Controller
                control={control}
                as={<Input id={"contact.email"} />}
                name="contact.email"
              />
              <FieldErrorMessage errors={errors} name="contact.email" />
            </Col>
          </Row>
        </div>
        <div className="white-container">
          <Typography.Title level={4}>Branding</Typography.Title>
          <Row style={{ width: "100%" }} gutter={12}>
            <Col span={6}>
              <label htmlFor={"logo"}>Logo</label>
              <Controller
                control={control}
                as={<FileUploadV2 limit={1} singleValue={true} />}
                name="logo"
              />
              <FieldErrorMessage errors={errors} name="logo" />
            </Col>
          </Row>
        </div>
      </Space>
      <div className="white-container">
        <div style={{ marginBottom: 10 }} />
        <Button loading={loading || loadingSubmit} htmlType="submit" role={"submit"}>
          Submit
        </Button>
      </div>
    </form>
  );
};

export default BrandForm;
