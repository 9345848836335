import React from "react";
import { Link } from "react-router-dom";
import { Table } from "./Table";
import { Api } from "../api";
import { Space } from "antd";
//import { CategoriesFilter } from './CategoriesFilter';
import BasicTableFilter from "./BasicTableFilter";
import { IMAGE_ROOT } from "../helpers/images";

const columns = [
  {
    title: "id",
    sorter: true,
    dataIndex: "_id",
    width: "20%",
    key: "_id",
    render: (text: string) => {
      return <Link to={`/brands/update/${text}`}>{text}</Link>;
    },
  },
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
    sorter: true,
    width: "20%",
  },
  {
    title: "Logo",
    dataIndex: "logo",
    key: "logo",
    width: "20%",
    render: (logo: string) => {
      if (!logo) return null;
      return (
        <img
          style={{ width: 80, height: 80, objectFit: "contain" }}
          alt="any"
          src={IMAGE_ROOT + logo}
        />
      );
    },
  },
];

const BrandTable = () => {
  return (
    <Space style={{ width: "100%" }} direction="vertical">
      <Table
        tableId={"brandsTable"}
        deleteApiCall={Api.brands.delete}
        allowDelete={true}
        restoreApiCall={Api.brands.restore}
        actions={(record: any) => <Link to={`/brands/update/${record._id}`}>View</Link>}
        Filter={BasicTableFilter}
        apiCall={Api.brands.table}
        columns={columns}
      />
    </Space>
  );
};

export default BrandTable;
